// src/slices/devicesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assetData: [],
  assetType: [],
  unassignAsset: [],
  assetCsvData: "",
};

const assetSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    updateAsset: (state, action) => {
      state.assetData = action.payload;
    },
    updateAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    updateunassignAsset: (state, action) => {
      state.unassignAsset = action.payload;
    },
    updateCsvData: (state, action) => {
      state.assetCsvData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  updateAsset,
  updateCsvData,
  updateAssetType,
  updateunassignAsset,
} = assetSlice.actions;
export default assetSlice.reducer;
