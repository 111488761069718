import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  AccordionSummary,
  CardContent,
  Accordion,
  Grid,
  Skeleton,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, formatDate, formatTime } from "../Order/util";
import { useGetData } from "../../hooks/useApi";
import { setMyOrders } from "../../slices/myOrderSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import { logout } from "../../slices/authSlice";
import axons from "../../assets/image/axons.svg";
import axonsmicro from "../../assets/image/axonmicro.svg";
import axonsmicropro from "../../assets/image/axonmicropro.svg";
import payngo from "../../assets/image/payngo.svg";
import deliveryservice from "../../assets/image/deliveryservice.svg";
import fleetmanagment from "../../assets/image/fleetmanagment.svg";
import OrderBasket from "../../assets/icons/OrderBasket.svg";
import location from "../../assets/icons/locationIcon.svg";
import CompanyInfo from "../../assets/icons/CompanyInfo.svg";
import axonsvarianttwo from "../../assets/image/axonsvarianttwo.svg";
import axonsmicroprotwo from "../../assets/image/axonsmicroprotwo.svg";
import axonsmicrotwo from "../../assets/image/axonsmicrotwo.svg"


const TrackOrder = () => {
  const getData = useGetData();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const [loading, setLoading] = useState(true);
  const selectedOrder = useSelector(
    (state) => state?.orders?.myOrders?.data?.[0] || {}
  );
  const firstOrderArray = useSelector(
    (state) => state?.orders?.myOrders?.data || []
  );
  const orders = firstOrderArray.length > 0 ? [firstOrderArray[0]] : [];

  const getOrders = async () => {
    try {
      setLoading(true);
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/orders",
        data: {},
      });
      if (data) {
        dispatch(setMyOrders(data));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
      dispatch(setMyOrders([]));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const predefinedSteps = [
    { name: "Order Confirmed", date: "" },
    { name: "Pick-Up", date: "" },
    { name: "In Transit", date: "" },
    { name: "Out for Delivery", date: "" },
    { name: "Delivered", date: "" },
  ];

  const mapBackendDataToSteps = (trackingData) => {
    return predefinedSteps.map((step) => {
      const trackingInfo = trackingData?.find(
        (tracking) =>
          tracking.status === step.name.toUpperCase().replace(" ", "_")
      );
      return trackingInfo
        ? { ...step, date: formatDate(trackingInfo.created_at) }
        : step;
    });
  };

  const steps = mapBackendDataToSteps(selectedOrder.tracking);


  const getOrderImage = (orderItems) => {
    if (!Array.isArray(orderItems)) return "";
  
    let hasSquareMicro = false;
    let hasRectangleMicro = false;
    let hasSquareMicroPro = false;
    let hasRectangleMicroPro = false;
  
    for (const item of orderItems) {
      const { name, type } = item;
  
      // Track specific combinations
      if (name === "Axons Micro") {
        if (type === "Variant 1") hasSquareMicro = true;
        if (type === "Variant 2") hasRectangleMicro = true;
      } else if (name === "Axons Micro Pro") {
        if (type === "Variant 1") hasSquareMicroPro = true;
        if (type === "Variant 2") hasRectangleMicroPro = true;
      }
  
      // Handle name-only cases where type is null
      if (!type) {
        if (name === "Pay N Go") return payngo;
        if (name === "Delivery As A Service") return deliveryservice;
        if (name === "Fleet Management") return fleetmanagment;
      }
    }
  
    // Handle mixed cases
    if (hasSquareMicro && hasRectangleMicroPro) {
      return axons; // Image for Axons Micro Variant 1 + Axons Micro Pro Variant 2
    }
    if (hasRectangleMicro && hasSquareMicroPro) {
      return axons; // Image for Axons Micro Variant 2 + Axons Micro Pro Variant 1
    }
  
    // Handle combined and individual conditions
    if (hasSquareMicro && hasSquareMicroPro) return axons; // Both Micro and Micro Pro Variant 1
    if (hasRectangleMicro && hasRectangleMicroPro) return axonsvarianttwo; // Both Micro and Micro Pro Variant 2
  
    if (hasSquareMicroPro) return axonsmicropro; // Micro Pro Variant 1
    if (hasRectangleMicroPro) return axonsmicroprotwo; // Micro Pro Variant 2
  
    if (hasSquareMicro) return axonsmicro; // Micro Variant 1
    if (hasRectangleMicro) return axonsmicrotwo; // Micro Variant 2
  
    return ""; // Default case
  };

  return (
    <Box sx={{ marginTop: "16px" }}>
      <Paper
        sx={{
          p: 2,
          borderTopLeftRadius: "0.625rem",
          borderTopRightRadius: "0.625rem",
          borderBottomLeftRadius: "0rem",
          borderBottomRightRadius: "0rem",
          boxShadow: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            background: "transparent",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              // ml: 2,
              fontSize: "1.375rem",
              color: "#283246",
            }}
          >
            Latest Order
          </Typography>
        </Box>
        {loading ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Skeleton
              variant="rectangular"
              width={120}
              height={120}
              sx={{ borderRadius: "8px", marginRight: 2 }}
            />
            <Box sx={{ flex: 1 }}>
              <Skeleton variant="text" width="60%" height={30} />
              <Skeleton variant="text" width="40%" height={20} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 1,
                }}
              >
                <Skeleton variant="text" width="30%" height={30} />
                <Skeleton variant="text" width="30%" height={30} />
              </Box>
            </Box>
          </Box>
        ) : (
          orders?.length &&
          orders?.map((order) => (
            <CardContent key={order?.id} sx={{ padding: "0px" }}>
              <Accordion
                expanded={false}
                sx={{
                  border: "1px solid #DDDEE1",
                  borderRadius: "0.688rem",
                  cursor: "text",
                }}
              >
                <AccordionSummary
                  expandIcon={null}
                  sx={{
                    cursor: "text !important",
                    background: "#FBFBFB",
                    "& .MuiAccordionSummary-content": {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#283246",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "DM Sans, Arial, sans-serif",
                            margin: 0,
                          }}
                        >
                          {formatDate(order?.created_at)}{" "}
                          {formatTime(order?.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Grid sx={{ marginTop: "20px" }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={getOrderImage(order?.order_item)}
                        alt="order-img"
                        height={"150"}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ padding: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#283246",
                            fontSize: "16px",
                            fontWeight: 600,
                            fontFamily: "DM Sans, Arial, sans-serif",
                          }}
                        >
                          Order ID:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#283246",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "DM Sans, Arial, sans-serif",
                          }}
                        >
                          {order?.tracking_id}
                        </Typography>
                      </Box>
                      {order?.order_item?.map((item) => {
                        return item?.name === "Axons Micro" ||
                          item?.name === "Axons Micro Pro" ? (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 500,
                              fontFamily: "DM Sans, Arial, sans-serif",
                              color: "#283246",
                              margin: 0,
                            }}
                          >
                            {item?.name} | Total Quantity: {item?.quantity}
                          </Typography>
                        ) : null;
                      })}
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: 400,
                          fontFamily: "DM Sans, Arial, sans-serif",
                          color: "#535A6A",
                          margin: 0,
                        }}
                      >
                        {order?.description}
                      </Typography>
                      {order?.tracking && (
                        <>
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              fontWeight: 600,
                              fontFamily: "DM Sans, Arial, sans-serif",
                              color: "#535A6A",
                              margin: 0,
                            }}
                          ></Typography>
                        </>
                      )}

                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          fontFamily: "DM Sans, Arial, sans-serif",
                          color: "#283246",
                          margin: 0,
                        }}
                      >
                        {formatCurrency(order?.order_total)}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
            </CardContent>
          ))
        )}
        <Box sx={{ marginTop: "22px" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton variant="text" width="70%" height={100} />
            </Box>
          ) : (
            <Stepper
              activeStep={steps?.findIndex((step) => step.date === "") - 1}
              alternativeLabel
            >
              {steps.map((step, index) => {
                const isCompleted =
                  index < steps?.findIndex((step) => step.date === "");
                const isActive =
                  index === steps?.findIndex((step) => step.date === "") - 1;
                return (
                  <Step key={index}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label": {
                          color: "#535A6A",
                        },
                        "& .Mui-completed, & .Mui-active": {
                          color: "#F99494 !important",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 500,
                            color:
                              isCompleted || isActive ? "#535A6A" : "#A9ACB4",
                          }}
                        >
                          {step.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 500,
                            color:
                              isCompleted || isActive ? "#535A6A" : "#A9ACB4",
                          }}
                        >
                          {step.date || ""}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </Box>
      </Paper>
      <Paper
        sx={{
          p: 2,
          borderBottomLeftRadius: "0.625rem",
          borderBottomRightRadius: "0.625rem",
          borderTopLeftRadius: "0rem",
          borderTopRightRadius: "0rem",
          boxShadow: "none",
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        {loading ? (
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="40%" height={20} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 1,
              }}
            >
              <Skeleton variant="text" width="30%" height={30} />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "5px",
              }}
            >
              <IconButton disableRipple sx={{ padding: "0px" }}>
                <img src={OrderBasket} alt="basket" />
              </IconButton>
              <Typography
                sx={{
                  color: "#F99494",
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: "DM Sans, Arial, sans-serif",
                  padding: "0.2rem",
                }}
              >
                Order Information
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Drop Off Estimation
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              7 days
            </Typography>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Order Total
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              {formatCurrency(selectedOrder.order_total)}
            </Typography>
          </Box>
        )}
        {loading ? (
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="40%" height={20} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 1,
              }}
            >
              <Skeleton variant="text" width="30%" height={30} />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "5px",
              }}
            >
              <IconButton disableRipple sx={{ padding: "0px" }}>
                <img src={location} alt="basket" />
              </IconButton>
              <Typography
                sx={{
                  color: "#F99494",
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: "DM Sans, Arial, sans-serif",
                  padding: "0.2rem",
                }}
              >
                Location
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Shipping address
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              {" "}
              {`${selectedOrder?.shipping_address?.street_address}, ${selectedOrder?.shipping_address?.city}, ${selectedOrder?.shipping_address?.state}`}
              <br />
              {`${selectedOrder?.shipping_address?.country}, ${selectedOrder?.shipping_address?.zip_code}`}
            </Typography>
          </Box>
        )}
        {loading ? (
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="40%" height={20} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 1,
              }}
            >
              <Skeleton variant="text" width="30%" height={30} />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                paddingLeft: "2px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                paddingBottom: "6px",
              }}
            >
              <IconButton disableRipple sx={{ padding: "0px" }}>
                <img src={CompanyInfo} alt="basket" />
              </IconButton>
              <Typography
                sx={{
                  color: "#F99494",
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: "DM Sans, Arial, sans-serif",
                }}
              >
                Company Information
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Full Name
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Axons Lab
            </Typography>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Email ID
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              axonslab@gmail.com
            </Typography>
            <Typography
              sx={{
                color: "#838487",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              Contact Number
            </Typography>
            <Typography
              sx={{
                color: "#283246",
                fontSize: "0.875rem",
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                padding: "0.2rem",
              }}
            >
              +91 9876543210
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default TrackOrder;
