// src/slices/usersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  detailedList:[],
  roleList:[]
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state.list = action.payload;
    },
    updateUsersDetails: (state, action) => {
      state.detailedList = action.payload;
    },
    updateUsersRoles: (state, action) => {
      state.roleList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateUsers,updateUsersDetails,updateUsersRoles } = usersSlice.actions;
export default usersSlice.reducer;
