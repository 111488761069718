// src/slices/planDetailSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planDetail: null,
  productPrice: null,
  devicePrices: "",
};

const planDetailSlice = createSlice({
  name: "planDetails",
  initialState,
  reducers: {
    setPlanDetails: (state, action) => {
      state.planDetail = action.payload;
    },
    setProductPrice: (state, action) => {
      state.productPrice = action.payload;
    },
    setDevicePrices: (state, action) => {
      state.devicePrices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { setPlanDetails, setProductPrice, setDevicePrices } =
  planDetailSlice.actions;
export default planDetailSlice.reducer;
