import { createSlice } from "@reduxjs/toolkit";

const now = new Date();
const hours = String(now.getHours()).padStart(2, "0");
const minutes = String(now.getMinutes()).padStart(2, "0");
const seconds = String(now.getSeconds()).padStart(2, "0");
const currentTime = `${hours}:${minutes}:${seconds}`;

const initialState = {
  firmwareData: {},
  SummaryData: {
    version: null,
    deviceCount: 0,
    updateData: now,
    updateTime: currentTime,
    isScheduleUpdate: false,
    comments: "",
  },
};

const firmwareSlice = createSlice({
  name: "firmware",
  initialState,
  reducers: {
    setFirmwareData: (state, action) => {
      state.firmwareData = action.payload;
    },
    updateSummaryData: (state, action) => {
      state.SummaryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateSummaryData, setFirmwareData } = firmwareSlice.actions;
export default firmwareSlice.reducer;
