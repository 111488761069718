import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subAccount: [],
  errors: {},
  unAssigneAxons: [],
  activeAccountStep: 1,
  addBasicDetails: {
    orgName: "",
    adminName: "",
    adminEmail: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  },
  editBasicDetails: {
    // orgName: "",
    // adminName: "",
    // adminEmail: "",
    // phone: "",
    // address: "",
    // city: "",
    // state: "",
    // country: "",
    // zipCode: "",
  },
  assetSelection: {
    selectedQrCode: [],
  },
  selectViewId: "",
  assignUnassignAsset: {},
  perPageOption: 10,
};

const savedStep = localStorage.getItem("activeAccountStep");
if (savedStep !== null) {
  initialState.activeAccountStep = parseInt(savedStep, 10);
}

const SubAccountSlice = createSlice({
  name: "subAccount",
  initialState,
  reducers: {
    updateSubAccounts: (state, action) => {
      state.subAccount = action.payload;
    },
    setErrorList: (state, action) => {
      state.errors = action.payload;
    },
    updateUnAssigneAxons: (state, action) => {
      state.unAssigneAxons = action.payload;
    },
    setActiveAccountStep: (state, action) => {
      state.activeAccountStep = action.payload;
      localStorage.setItem("activeAccountStep", action.payload);
    },
    resetAccountStep: (state, action) => {
      state.activeAccountStep = 1;
      localStorage.removeItem("activeAccountStep");
    },
    setBasicDetails: (state, action) => {
      state.addBasicDetails = {
        ...state.addBasicDetails,
        ...action.payload,
      };
    },
    setEditBasicDetails: (state, action) => {
      state.editBasicDetails = {
        ...state.editBasicDetails,
        ...action.payload,
      };
    },
    setAssetSelection: (state, action) => {
      state.assetSelection = {
        ...state.assetSelection,
        ...action.payload,
      };
    },
    setSelectedViewId: (state, action) => {
      state.selectViewId = action.payload;
    },
    setAssignUnassignAsset: (state, action) => {
      state.assignUnassignAsset = action.payload;
    },
    setPerPageOption: (state, action) => {
      state.perPageOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  updateSubAccounts,
  updateUnAssigneAxons,
  setActiveAccountStep,
  resetAccountStep,
  setBasicDetails,
  setAssetSelection,
  setSelectedViewId,
  setEditBasicDetails,
  setAssignUnassignAsset,
  setPerPageOption,
  setErrorList,
} = SubAccountSlice.actions;
export default SubAccountSlice.reducer;
