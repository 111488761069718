import { Box } from "@mui/material";
import dashboardLoader from "../assets/Lottie/logo_animation.json";
import React from "react";
import Lottie from "lottie-react";

export default function LoadingUser() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width:'100%'
      }}
    >
      {/* <img src={dashboardLoader} alt="loading-gif" /> */}
      {/* <dashboardLoader /> */}
      <Lottie
        style={{
          background: "transparent",
          width: "15%",
          // height: "2rem",
        }}
        animationData={dashboardLoader}
        loop={true}
      />
    </Box>
  );
}
