// src/slices/devicesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  csvData: "",
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    updateDevices: (state, action) => {
      state.data = action.payload;
    },
    updateCsvData: (state, action) => {
      state.csvData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateDevices, updateCsvData } = devicesSlice.actions;
export default devicesSlice.reducer;
