import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: null,
};

const permissionsSlice = createSlice({
  name: "Permissons",
  initialState,
  reducers: {
    updatePermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updatePermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
