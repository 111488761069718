export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

export const formatTime = (dateString) => {
  // Check if dateString is valid
  if (!dateString) {
    return "-";
  }

  // Replace space with 'T' to make it ISO-compliant
  const isoString = dateString.replace(" ", "T") + "Z";

  // Parse the date string as a valid UTC date
  const date = new Date(isoString);
  if (isNaN(date.getTime())) {
    return "-"; // Handle invalid date
  }

  // Format the time in the user's local time zone
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDateTime = (dateString) => {
  // Check if dateString is valid
  if (!dateString) {
    return "-";
  }

  try {
    // Replace space with 'T' to make it ISO-compliant
    const isoString = dateString.replace(" ", "T") + "Z";

    // Parse the date string as a valid UTC date
    const date = new Date(isoString);

    // Check if the date is valid (not NaN or Invalid Date)
    if (isNaN(date.getTime())) {
      return "-"; // Handle invalid date
    }

    // Format date and time as 'YYYY-MM-DD HH:mm:ss'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    // Catch any unexpected errors and return "-"
    return "-";
  }
};

export const convertMinutesToTimeFormat = (minutes = "") => {
  if (minutes === "") {
    return "";
  }

  const totalSeconds = Math.floor(minutes * 60); // Ensure totalSeconds is an integer
  const days = Math.floor(totalSeconds / (24 * 3600)); // Calculate the number of days
  const remainingSeconds = totalSeconds % (24 * 3600); // Remaining seconds after days
  const hours = Math.floor(remainingSeconds / 3600); // Calculate hours
  const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60); // Remaining minutes
  const seconds = remainingSeconds % 60; // Remaining seconds

  // Format the result as "X days HH:MM:SS" or "HH:MM:SS"
  if (days > 0) {
    return `${days} days ${hours.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  } else {
    return `${hours.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
};

export const formatCurrency = (amount) => {
  if (isNaN(amount)) {
    return "-";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatErrorMessage = (error) => {
  if (error?.errors && typeof error.errors === "object") {
    const { errors } = error;

    const errorList = Object.values(errors)
      .flat() // Flatten arrays (e.g., arrays of error messages)
      .filter((msg) => msg); // Remove empty or falsy values

    return errorList.length ? errorList : "";
  }

  // Fallback for raw API error structure
  if (error?.response?.data) {
    const { message, errors } = error.response.data;

    if (errors && typeof errors === "object") {
      const errorList = Object.values(errors)
        .flat()
        .filter((msg) => msg);

      return errorList.length ? errorList : "";
    }

    return message ? [message] : "";
  }

  // If no error is present, return an empty string
  return "";
};
