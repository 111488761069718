// src/slices/settingsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedOrder: null,
  myOrders: "",
};

const myOrderSlice = createSlice({
  name: "myorder",
  initialState,
  reducers: {
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    setMyOrders: (state, action) => {
      state.myOrders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { setSelectedOrder, setMyOrders } = myOrderSlice.actions;
export default myOrderSlice.reducer;
