import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 1,
  firmware: {
    firmwareData: null,
    selectedFirmware: null,
    search: null,
    page: 0,
  },
  device: {
    search: null,
    status: null,
    page: 0,
    deviceData: null,
    firmwareVersion: [],
    hardwareVersion: [],
    selectedDevice: [],
    deSelectedDevice: [],
    rowPerPage:10,
    filters: {},
  },
  schedule: {
    scheduleUpdate: false,
    scheduleUpdateTime: null,
    comments: null,
  },
};

const savedStep = localStorage.getItem("activeStep");
if (savedStep !== null) {
  initialState.activeStep = parseInt(savedStep, 10);
}

export const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
      localStorage.setItem("activeStep", action.payload);
    },
    resetStep: (state, action) => {
      state.activeStep = 1;
      localStorage.removeItem("activeStep");
    },
    setFirmwareActions: (state, action) => {
      state.firmware = {
        ...state.firmware,
        ...action.payload,
      };
    },
    
    setDeviceActions: (state, action) => {
      state.device = {
        ...state.device,
        ...action.payload,
      };
    },

    setScheduleActions: (state, action) => {
      state.schedule = {
        ...state.schedule,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  setActiveStep,
  resetStep,
  setFirmwareActions,
  setDeviceActions,
  setScheduleActions,
} = stepperSlice.actions;
export default stepperSlice.reducer;
