import React, { createContext, useState, useEffect } from "react";

const getRandomSpeed = () => {
  const speeds = [10, 20, 40, 50];
  return speeds[Math.floor(Math.random() * speeds.length)];
};

const initialDevices = [
  {
    id: 1,
    name: "Axons-01",
    imei: "#40583888",
    firmware: "0399331.3",
    status: "Shipping",
    hardware: "0033435",
    mfgDate: "6 Aug 2023",
    firstConnected: "09 Jun 2024",
    lastConnected: "19 Jun 2024",
    lat: 23.017056,
    lng: 72.615138,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {
      "2024-06-22": [
        {
          time: 0,
          label: "12pm",
          lat: 23.03959445230207,
          lng: 72.52898074781024,
          speed: getRandomSpeed(),
        },
        {
          time: 1,
          label: "12:05pm",
          lat: 23.039786607808495,
          lng: 72.52784501653883,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "12:10pm",
          lat: 23.038882068520607,
          lng: 72.52749869489642,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "12:15pm",
          lat: 23.038647730926755,
          lng: 72.52748850896569,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "12:20pm",
          lat: 23.03865452641263,
          lng: 72.52748197981398,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "12:25pm",
          lat: 23.037999105976766,
          lng: 72.52757415013266,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "12:30pm",
          lat: 23.037729226046793,
          lng: 72.52770821604909,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "12:35pm",
          lat: 23.03698898117898,
          lng: 72.52759928749231,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "12:40pm",
          lat: 23.036372107349464,
          lng: 72.52755739189291,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "12:45pm",
          lat: 23.036356682387776,
          lng: 72.52754901375496,
          speed: getRandomSpeed(),
        },
        {
          time: 10,
          label: "12:50pm",
          lat: 23.034282423184635,
          lng: 72.52748198079595,
          speed: getRandomSpeed(),
        },
      ],
      "2024-05-16": [
        {
          time: 0,
          label: "12pm",
          lat: 23.03959445230207,
          lng: 72.52898074781024,
          speed: getRandomSpeed(),
        },
        {
          time: 1,
          label: "12:05pm",
          lat: 23.039786607808495,
          lng: 72.52784501653883,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "12:10pm",
          lat: 23.038882068520607,
          lng: 72.52749869489642,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "12:15pm",
          lat: 23.038647730926755,
          lng: 72.52748850896569,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "12:20pm",
          lat: 23.03865452641263,
          lng: 72.52748197981398,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "12:25pm",
          lat: 23.037999105976766,
          lng: 72.52757415013266,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "12:30pm",
          lat: 23.037729226046793,
          lng: 72.52770821604909,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "12:35pm",
          lat: 23.03698898117898,
          lng: 72.52759928749231,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "12:40pm",
          lat: 23.036372107349464,
          lng: 72.52755739189291,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "12:45pm",
          lat: 23.036356682387776,
          lng: 72.52754901375496,
          speed: getRandomSpeed(),
        },
        {
          time: 10,
          label: "12:50pm",
          lat: 23.034282423184635,
          lng: 72.52748198079595,
          speed: getRandomSpeed(),
        },
      ],
    },
  },
  {
    id: 2,
    name: "Axons-02",
    imei: "#59754838",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033424",
    mfgDate: "24 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "21 Apr 2024",
    lat: 23.06028,
    lng: 72.570034,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {
      // "2024-06-22": [
      //   {
      //     label: "5pm",
      //     time: 0,
      //     lat: 23.03381156353693,
      //     lng: 72.53539694514839,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 1,
      //     label: "5:05pm",
      //     lat: 23.030288481557662,
      //     lng: 72.53263784874832,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 2,
      //     label: "5:10pm",
      //     lat: 23.02733663926611,
      //     lng: 72.53122381184389,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 3,
      //     label: "5:15pm",
      //     lat: 23.027231867457942,
      //     lng: 72.53115135632984,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 4,
      //     label: "5:20pm",
      //     lat: 23.024470405939354,
      //     lng: 72.53018567258997,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 5,
      //     label: "5:25pm",
      //     lat: 23.018407688580908,
      //     lng: 72.53015118388555,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 6,
      //     label: "5:30pm",
      //     lat: 23.018308434886606,
      //     lng: 72.53014897995604,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 7,
      //     label: "5:35pm",
      //     lat: 23.016975234366726,
      //     lng: 72.53014897995604,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 8,
      //     label: "5:40pm",
      //     lat: 23.01589596715033,
      //     lng: 72.53039040089055,
      //     speed: getRandomSpeed(),
      //   },
      //   {
      //     time: 9,
      //     label: "5:45pm",
      //     lat: 23.014657973531357,
      //     lng: 72.53059733312065,
      //     speed: getRandomSpeed(),
      //   },
      // ],
      "2024-05-16": [
        {
          label: "5pm",
          time: 0,
          lat: 23.03381156353693,
          lng: 72.53539694514839,
          speed: getRandomSpeed(),
        },
        {
          time: 1,
          label: "5:05pm",
          lat: 23.030288481557662,
          lng: 72.53263784874832,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "5:10pm",
          lat: 23.02733663926611,
          lng: 72.53122381184389,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "5:15pm",
          lat: 23.027231867457942,
          lng: 72.53115135632984,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "5:20pm",
          lat: 23.024470405939354,
          lng: 72.53018567258997,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "5:25pm",
          lat: 23.018407688580908,
          lng: 72.53015118388555,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "5:30pm",
          lat: 23.018308434886606,
          lng: 72.53014897995604,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "5:35pm",
          lat: 23.016975234366726,
          lng: 72.53014897995604,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "5:40pm",
          lat: 23.01589596715033,
          lng: 72.53039040089055,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "5:45pm",
          lat: 23.014657973531357,
          lng: 72.53059733312065,
          speed: getRandomSpeed(),
        },
      ],
      "2024-06-22": [
        {
          time: 0,
          label: "3:00pm",
          lat: 23.042361664285067,
          lng: 72.56956453441362,
          speed: getRandomSpeed(),
        },
        {
          time: 1,
          label: "3:05pm",
          lat: 23.041918797290307,
          lng: 72.56950584373894,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "3:10pm",
          lat: 23.041184282967222,
          lng: 72.56942367679358,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "3:15pm",
          lat: 23.040654998104657,
          lng: 72.5693532479855,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "3:20pm",
          lat: 23.040276936214525,
          lng: 72.56931803357958,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "3:25pm",
          lat: 23.040244530860264,
          lng: 72.56856679294188,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "3:30pm",
          lat: 23.040212125498215,
          lng: 72.56794467178699,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "3:35pm",
          lat: 23.040114909366196,
          lng: 72.56702909726059,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "3:40pm",
          lat: 23.040050098571953,
          lng: 72.56648914305111,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "3:45pm",
          lat: 23.04068740335927,
          lng: 72.56633654729578,
          speed: getRandomSpeed(),
        },
        {
          time: 10,
          label: "3:50pm",
          lat: 23.041465127151497,
          lng: 72.56616047527166,
          speed: getRandomSpeed(),
        },
        {
          time: 11,
          label: "3:55pm",
          lat: 23.042113226881554,
          lng: 72.56600787951629,
          speed: getRandomSpeed(),
        },
        {
          time: 12,
          label: "4:00pm",
          lat: 23.042718117150116,
          lng: 72.56584354562753,
          speed: getRandomSpeed(),
        },
        {
          time: 13,
          label: "4:05pm",
          lat: 23.043625664881333,
          lng: 72.5655970447915,
          speed: getRandomSpeed(),
        },
        {
          time: 14,
          label: "4:10pm",
          lat: 23.044738433741614,
          lng: 72.56532706768871,
          speed: getRandomSpeed(),
        },
        {
          time: 15,
          label: "4:15pm",
          lat: 23.045137143345272,
          lng: 72.56523913284576,
          speed: getRandomSpeed(),
        },
        {
          time: 16,
          label: "4:20pm",
          lat: 23.04687485169761,
          lng: 72.56476856632074,
          speed: getRandomSpeed(),
        },
        {
          time: 17,
          label: "4:25pm",
          lat: 23.046787620577774,
          lng: 72.56675931075915,
          speed: getRandomSpeed(),
        },
        {
          time: 18,
          label: "4:30pm",
          lat: 23.0468312361442,
          lng: 72.56792410026614,
          speed: getRandomSpeed(),
        },
        {
          time: 19,
          label: "4:35pm",
          lat: 23.04691846723685,
          lng: 72.5686081317624,
          speed: getRandomSpeed(),
        },
        {
          time: 20,
          label: "4:40pm",
          lat: 23.047055544553075,
          lng: 72.5703147567358,
          speed: getRandomSpeed(),
        },
        {
          time: 21,
          label: "4:45pm",
          lat: 23.047454439552894,
          lng: 72.57073470988564,
          speed: getRandomSpeed(),
        },
        {
          time: 22,
          label: "4:50pm",
          lat: 23.047921746153406,
          lng: 72.570958160792,
          speed: getRandomSpeed(),
        },
        {
          time: 23,
          label: "4:55pm",
          lat: 23.048754909147007,
          lng: 72.57109358558344,
          speed: getRandomSpeed(),
        },
        {
          time: 24,
          label: "5:00pm",
          lat: 23.049764405038218,
          lng: 72.57123591791449,
          speed: getRandomSpeed(),
        },
        {
          time: 25,
          label: "5:05pm",
          lat: 23.05046236240996,
          lng: 72.5712697741109,
          speed: getRandomSpeed(),
        },
        {
          time: 26,
          label: "5:10pm",
          lat: 23.051091779610246,
          lng: 72.57133071526923,
          speed: getRandomSpeed(),
        },
        {
          time: 27,
          label: "5:15pm",
          lat: 23.0529924745792,
          lng: 72.57144582634135,
          speed: getRandomSpeed(),
        },
        {
          time: 28,
          label: "5:20pm",
          lat: 23.055081784108964,
          lng: 72.57156074028126,
          speed: getRandomSpeed(),
        },
        {
          time: 29,
          label: "5:25pm",
          lat: 23.055860829596256,
          lng: 72.57224614182445,
          speed: getRandomSpeed(),
        },
        {
          time: 30,
          label: "5:30pm",
          lat: 23.055955769118697,
          lng: 72.5726140712089,
          speed: getRandomSpeed(),
        },
        {
          time: 31,
          label: "5:35pm",
          lat: 23.055747246086057,
          lng: 72.57288602125737,
          speed: getRandomSpeed(),
        },
        {
          time: 32,
          label: "5:40pm",
          lat: 23.055296835233463,
          lng: 72.57451772154377,
          speed: getRandomSpeed(),
        },
        {
          time: 33,
          label: "5:45pm",
          lat: 23.054796376962344,
          lng: 72.5755150541874,
          speed: getRandomSpeed(),
        },
        {
          time: 34,
          label: "5:50pm",
          lat: 23.054129096374126,
          lng: 72.57688405189853,
          speed: getRandomSpeed(),
        },
        {
          time: 35,
          label: "5:55pm",
          lat: 23.053846064235216,
          lng: 72.5773366793373,
          speed: getRandomSpeed(),
        }
      ]
    },
  },
  {
    id: 3,
    name: "Axons-03",
    imei: "#67320037",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033491",
    mfgDate: "9 Oct 2023",
    firstConnected: "15 Mar 2024",
    lastConnected: "6 Jun 2024",
    lat: 23.057443,
    lng: 72.553011,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
      dates: {
        "2024-06-22": [
          {
            "time": 0,
            "label": "1:40pm",
            "lat": 23.04019133792717,
            "lng": 72.57152576977845,
            "speed": getRandomSpeed()
          },
          {
            "time": 1,
            "label": "1:45pm",
            "lat": 23.03948281996483,
            "lng": 72.5714528877478,
            "speed": getRandomSpeed()
          },
          {
            "time": 2,
            "label": "1:50pm",
            "lat": 23.039171552401584,
            "lng": 72.5714173811154,
            "speed": getRandomSpeed()
          },
          {
            "time": 3,
            "label": "1:55pm",
            "lat": 23.038831048934483,
            "lng": 72.57139121833816,
            "speed": getRandomSpeed()
          },
          {
            "time": 4,
            "label": "2:00pm",
            "lat": 23.03844411213744,
            "lng": 72.57136505555462,
            "speed": getRandomSpeed()
          },
          {
            "time": 5,
            "label": "2:05pm",
            "lat": 23.038273859593872,
            "lng": 72.5713594492457,
            "speed": getRandomSpeed()
          },
          {
            "time": 6,
            "label": "2:10pm",
            "lat": 23.03808812930062,
            "lng": 72.571361318018,
            "speed": getRandomSpeed()
          },
          {
            "time": 7,
            "label": "2:15pm",
            "lat": 23.03791615657937,
            "lng": 72.57135010539369,
            "speed": getRandomSpeed()
          },
          {
            "time": 8,
            "label": "2:20pm",
            "lat": 23.037582528872633,
            "lng": 72.57132581138717,
            "speed": getRandomSpeed()
          },
          {
            "time": 9,
            "label": "2:25pm",
            "lat": 23.037410555507122,
            "lng": 72.57132581138552,
            "speed": getRandomSpeed()
          },
          {
            "time": 10,
            "label": "2:30pm",
            "lat": 23.03723342270787,
            "lng": 72.57132020507498,
            "speed": getRandomSpeed()
          },
          {
            "time": 11,
            "label": "2:35pm",
            "lat": 23.03688775511965,
            "lng": 72.57132207385052,
            "speed": getRandomSpeed()
          },
          {
            "time": 12,
            "label": "2:40pm",
            "lat": 23.036536927408804,
            "lng": 72.57133328646844,
            "speed": getRandomSpeed()
          },
          {
            "time": 13,
            "label": "2:45pm",
            "lat": 23.036239411037144,
            "lng": 72.571361318018,
            "speed": getRandomSpeed()
          },
          {
            "time": 14,
            "label": "2:50pm",
            "lat": 23.03575100123338,
            "lng": 72.57138934956748,
            "speed": getRandomSpeed()
          },
          {
            "time": 15,
            "label": "2:55pm",
            "lat": 23.035393291519924,
            "lng": 72.57141177480966,
            "speed": getRandomSpeed()
          },
          {
            "time": 16,
            "label": "3:00pm",
            "lat": 23.03467099016855,
            "lng": 72.57144728144044,
            "speed": getRandomSpeed()
          },
          {
            "time": 17,
            "label": "3:05pm",
            "lat": 23.03433563465454,
            "lng": 72.57146223159816,
            "speed": getRandomSpeed()
          },
          {
            "time": 18,
            "label": "3:10pm",
            "lat": 23.033977921181688,
            "lng": 72.57148278807125,
            "speed": getRandomSpeed()
          },
          {
            "time": 19,
            "label": "3:15pm",
            "lat": 23.033474025118565,
            "lng": 72.5715070820809,
            "speed": getRandomSpeed()
          },
          {
            "time": 20,
            "label": "3:20pm",
            "lat": 23.03277923018119,
            "lng": 72.57154258871168,
            "speed": getRandomSpeed()
          },
          {
            "time": 21,
            "label": "3:25pm",
            "lat": 23.03224022659029,
            "lng": 72.5715855704349,
            "speed": getRandomSpeed()
          },
          {
            "time": 22,
            "label": "3:30pm",
            "lat": 23.031853364497167,
            "lng": 72.57161920829657,
            "speed": getRandomSpeed()
          },
          {
            "time": 23,
            "label": "3:35pm",
            "lat": 23.03132247853918,
            "lng": 72.57166592755797,
            "speed": getRandomSpeed()
          },
          {
            "time": 24,
            "label": "3:40pm",
            "lat": 23.0309406796315,
            "lng": 72.57173320327973,
            "speed": getRandomSpeed()
          },
          {
            "time": 25,
            "label": "3:45pm",
            "lat": 23.03051588408057,
            "lng": 72.57182103547424,
            "speed": getRandomSpeed()
          },
          {
            "time": 26,
            "label": "3:50pm",
            "lat": 23.02992843911855,
            "lng": 72.57197801215972,
            "speed": getRandomSpeed()
          },
          {
            "time": 27,
            "label": "3:55pm",
            "lat": 23.029574153620942,
            "lng": 72.57209165622007,
            "speed": getRandomSpeed()
          },
          {
            "time": 28,
            "label": "4:00pm",
            "lat": 23.028852489907976,
            "lng": 72.57234207140766,
            "speed": getRandomSpeed()
          },
          {
            "time": 29,
            "label": "4:05pm",
            "lat": 23.02849132218776,
            "lng": 72.57249323414379,
            "speed": getRandomSpeed()
          },
          {
            "time": 30,
            "label": "4:10pm",
            "lat": 23.02811207784285,
            "lng": 72.57265021082446,
            "speed": getRandomSpeed()
          },
          {
            "time": 31,
            "label": "4:15pm",
            "lat": 23.027611305275997,
            "lng": 72.57282963559479,
            "speed": getRandomSpeed()
          },
          {
            "time": 32,
            "label": "4:20pm",
            "lat": 23.027315489063483,
            "lng": 72.57291373024825,
            "speed": getRandomSpeed()
          },
          {
            "time": 33,
            "label": "4:25pm",
            "lat": 23.026960241215306,
            "lng": 72.57298100596972,
            "speed": getRandomSpeed()
          },
          {
            "time": 34,
            "label": "4:30pm",
            "lat": 23.026791694034173,
            "lng": 72.57301464382977,
            "speed": getRandomSpeed()
          }
        ],
        "2024-05-16": [
          {
            "time": 0,
            "label": "1:40pm",
            "lat": 23.04019133792717,
            "lng": 72.57152576977845,
            "speed": getRandomSpeed()
          },
          {
            "time": 1,
            "label": "1:45pm",
            "lat": 23.03948281996483,
            "lng": 72.5714528877478,
            "speed": getRandomSpeed()
          },
          {
            "time": 2,
            "label": "1:50pm",
            "lat": 23.039171552401584,
            "lng": 72.5714173811154,
            "speed": getRandomSpeed()
          },
          {
            "time": 3,
            "label": "1:55pm",
            "lat": 23.038831048934483,
            "lng": 72.57139121833816,
            "speed": getRandomSpeed()
          },
          {
            "time": 4,
            "label": "2:00pm",
            "lat": 23.03844411213744,
            "lng": 72.57136505555462,
            "speed": getRandomSpeed()
          },
          {
            "time": 5,
            "label": "2:05pm",
            "lat": 23.038273859593872,
            "lng": 72.5713594492457,
            "speed": getRandomSpeed()
          },
          {
            "time": 6,
            "label": "2:10pm",
            "lat": 23.03808812930062,
            "lng": 72.571361318018,
            "speed": getRandomSpeed()
          },
          {
            "time": 7,
            "label": "2:15pm",
            "lat": 23.03791615657937,
            "lng": 72.57135010539369,
            "speed": getRandomSpeed()
          },
          {
            "time": 8,
            "label": "2:20pm",
            "lat": 23.037582528872633,
            "lng": 72.57132581138717,
            "speed": getRandomSpeed()
          },
          {
            "time": 9,
            "label": "2:25pm",
            "lat": 23.037410555507122,
            "lng": 72.57132581138552,
            "speed": getRandomSpeed()
          },
          {
            "time": 10,
            "label": "2:30pm",
            "lat": 23.03723342270787,
            "lng": 72.57132020507498,
            "speed": getRandomSpeed()
          },
          {
            "time": 11,
            "label": "2:35pm",
            "lat": 23.03688775511965,
            "lng": 72.57132207385052,
            "speed": getRandomSpeed()
          },
          {
            "time": 12,
            "label": "2:40pm",
            "lat": 23.036536927408804,
            "lng": 72.57133328646844,
            "speed": getRandomSpeed()
          },
          {
            "time": 13,
            "label": "2:45pm",
            "lat": 23.036239411037144,
            "lng": 72.571361318018,
            "speed": getRandomSpeed()
          },
          {
            "time": 14,
            "label": "2:50pm",
            "lat": 23.03575100123338,
            "lng": 72.57138934956748,
            "speed": getRandomSpeed()
          },
          {
            "time": 15,
            "label": "2:55pm",
            "lat": 23.035393291519924,
            "lng": 72.57141177480966,
            "speed": getRandomSpeed()
          },
          {
            "time": 16,
            "label": "3:00pm",
            "lat": 23.03467099016855,
            "lng": 72.57144728144044,
            "speed": getRandomSpeed()
          },
          {
            "time": 17,
            "label": "3:05pm",
            "lat": 23.03433563465454,
            "lng": 72.57146223159816,
            "speed": getRandomSpeed()
          },
          {
            "time": 18,
            "label": "3:10pm",
            "lat": 23.033977921181688,
            "lng": 72.57148278807125,
            "speed": getRandomSpeed()
          },
          {
            "time": 19,
            "label": "3:15pm",
            "lat": 23.033474025118565,
            "lng": 72.5715070820809,
            "speed": getRandomSpeed()
          },
          {
            "time": 20,
            "label": "3:20pm",
            "lat": 23.03277923018119,
            "lng": 72.57154258871168,
            "speed": getRandomSpeed()
          },
          {
            "time": 21,
            "label": "3:25pm",
            "lat": 23.03224022659029,
            "lng": 72.5715855704349,
            "speed": getRandomSpeed()
          },
          {
            "time": 22,
            "label": "3:30pm",
            "lat": 23.031853364497167,
            "lng": 72.57161920829657,
            "speed": getRandomSpeed()
          },
          {
            "time": 23,
            "label": "3:35pm",
            "lat": 23.03132247853918,
            "lng": 72.57166592755797,
            "speed": getRandomSpeed()
          },
          {
            "time": 24,
            "label": "3:40pm",
            "lat": 23.0309406796315,
            "lng": 72.57173320327973,
            "speed": getRandomSpeed()
          },
          {
            "time": 25,
            "label": "3:45pm",
            "lat": 23.03051588408057,
            "lng": 72.57182103547424,
            "speed": getRandomSpeed()
          },
          {
            "time": 26,
            "label": "3:50pm",
            "lat": 23.02992843911855,
            "lng": 72.57197801215972,
            "speed": getRandomSpeed()
          },
          {
            "time": 27,
            "label": "3:55pm",
            "lat": 23.029574153620942,
            "lng": 72.57209165622007,
            "speed": getRandomSpeed()
          },
          {
            "time": 28,
            "label": "4:00pm",
            "lat": 23.028852489907976,
            "lng": 72.57234207140766,
            "speed": getRandomSpeed()
          },
          {
            "time": 29,
            "label": "4:05pm",
            "lat": 23.02849132218776,
            "lng": 72.57249323414379,
            "speed": getRandomSpeed()
          },
          {
            "time": 30,
            "label": "4:10pm",
            "lat": 23.02811207784285,
            "lng": 72.57265021082446,
            "speed": getRandomSpeed()
          },
          {
            "time": 31,
            "label": "4:15pm",
            "lat": 23.027611305275997,
            "lng": 72.57282963559479,
            "speed": getRandomSpeed()
          },
          {
            "time": 32,
            "label": "4:20pm",
            "lat": 23.027315489063483,
            "lng": 72.57291373024825,
            "speed": getRandomSpeed()
          },
          {
            "time": 33,
            "label": "4:25pm",
            "lat": 23.026960241215306,
            "lng": 72.57298100596972,
            "speed": getRandomSpeed()
          },
          {
            "time": 34,
            "label": "4:30pm",
            "lat": 23.026791694034173,
            "lng": 72.57301464382977,
            "speed": getRandomSpeed()
          }
        ]
      }
      
  },
  {
    id: 4,
    name: "Axons-04",
    imei: "#74073510",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033483",
    mfgDate: "13 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "14 Mar 2024",
    lat: 23.037269,
    lng: 72.556233,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {
      "2024-06-22": [
        {
          time: 0,
          label: "2:00pm",
          lat: 23.029599371546524,
          lng: 72.54135139492169,
          speed:  getRandomSpeed(),
        },
        {
          time: 1,
          label: "2:05pm",
          lat: 23.030717632582466,
          lng: 72.53951099045102,
          speed:  getRandomSpeed(),
        },
        {
          time: 2,
          label: "2:10pm",
          lat: 23.034105582703987,
          lng: 72.53561741875654,
          speed:  getRandomSpeed(),
        },
        {
          time: 3,
          label: "2:15pm",
          lat: 23.031044435986814,
          lng: 72.53314323423962,
          speed:  getRandomSpeed(),
        },
        {
          time: 4,
          label: "2:20pm",
          lat: 23.028171652077845,
          lng: 72.53155062573228,
          speed:  getRandomSpeed(),
        },
        {
          time: 5,
          label: "2:25pm",
          lat: 23.0243339749387,
          lng: 72.53017653631198,
          speed:  getRandomSpeed(),
        },
        {
          time: 6,
          label: "2:30pm",
          lat: 23.019397683381627,
          lng: 72.53015776679044,
          speed:  getRandomSpeed(),
        },
        {
          time: 7,
          label: "2:35pm",
          lat: 23.017357796409186,
          lng: 72.53015553863088,
          speed:  getRandomSpeed(),
        },
        {
          time: 8,
          label: "2:40pm",
          lat: 23.014629194175427,
          lng: 72.53055129998512,
          speed:  getRandomSpeed(),
        },
        {
          time: 9,
          label: "2:45pm",
          lat: 23.015369940246487,
          lng: 72.53370497385126,
          speed:  getRandomSpeed(),
        },
        {
          time: 10,
          label: "2:50pm",
          lat: 23.012844663350634,
          lng: 72.53375839311451,
          speed:  getRandomSpeed(),
        }
      ]
      ,
      "2024-05-16": [
        {
          time: 1,
          label: "1:45pm",
          lat: 23.02921508556713,
          lng: 72.5294797030648,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "1:50pm",
          lat: 23.02823574055425,
          lng: 72.53154096653535,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "1:55pm",
          lat: 23.02727181134945,
          lng: 72.53123931822299,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "2pm",
          lat: 23.027289827303818,
          lng: 72.53124103654218,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "2:05pm",
          lat: 23.025506183984703,
          lng: 72.53058348092503,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "2:10pm",
          lat: 23.025506191991866,
          lng: 72.53057195701837,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "2:15pm",
          lat: 23.024916947495015,
          lng: 72.53039314803439,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "2:20pm",
          lat: 23.0244020200159,
          lng: 72.53031239559058,
          speed: getRandomSpeed(),
        },
        //changes
        {
          time: 29,
          label: "2:25pm",
          lat: 23.023972027521197,
          lng: 72.53032969968592,
          speed: getRandomSpeed(),
        },
        {
          time: 30,
          label: "2:30pm",
          lat: 23.023361541993268,
          lng: 72.53033546771769,
          speed: getRandomSpeed(),
        },
        {
          time: 31,
          label: "2:35pm",
          lat: 23.023181050090926,
          lng: 72.53032393165415,
          speed: getRandomSpeed(),
        },
        {
          time: 32,
          label: "2:40pm",
          lat: 23.023240943255146,
          lng: 72.53032484831826,
          speed: getRandomSpeed(),
        },
        {
          time: 33,
          label: "2:45pm",
          lat: 23.021775765400747,
          lng: 72.53027870406402,
          speed: getRandomSpeed(),
        },
        {
          time: 34,
          label: "2:50pm",
          lat: 23.021335147207566,
          lng: 72.53029024012764,
          speed: getRandomSpeed(),
        },
        {
          time: 35,
          label: "2:55pm",
          lat: 23.021202430601946,
          lng: 72.53024409587337,
          speed: getRandomSpeed(),
        },
        {
          time: 36,
          label: "3pm",
          lat: 23.021213047164906,
          lng: 72.5302267899875,
          speed: getRandomSpeed(),
        },
        {
          time: 37,
          label: "3:05pm",
          lat: 23.019317839937898,
          lng: 72.53019218179801,
          speed: getRandomSpeed(),
        },
        {
          time: 38,
          label: "3:10pm",
          lat: 23.01910018138041,
          lng: 72.53018064573439,
          speed: getRandomSpeed(),
        },
        {
          time: 39,
          label: "3:15pm",
          lat: 23.019135931201035,
          lng: 72.53019144263769,
          speed: getRandomSpeed(),
        },
        {
          time: 40,
          label: "3:20pm",
          lat: 23.017352180014925,
          lng: 72.53024335492367,
          speed: getRandomSpeed(),
        },
        {
          time: 41,
          label: "3:25pm",
          lat: 23.017341007469867,
          lng: 72.53022218504054,
          speed: getRandomSpeed(),
        },
        {
          time: 42,
          label: "3:30pm",
          lat: 23.015833294491443,
          lng: 72.53034908173856,
          speed: getRandomSpeed(),
        },
        {
          time: 43,
          label: "3:35pm",
          lat: 23.015477599183697,
          lng: 72.53041253008695,
          speed: getRandomSpeed(),
        },
        {
          time: 44,
          label: "3:40pm",
          lat: 23.015493522985793,
          lng: 72.53041829354191,
          speed: getRandomSpeed(),
        },
        {
          time: 45,
          label: "3:45pm",
          lat: 23.014811328696055,
          lng: 72.53051635008103,
          speed: getRandomSpeed(),
        },
      ],
    },
  },
  {
    id: 5,
    name: "Axons-05",
    imei: "#83137530",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033451",
    mfgDate: "1 Oct 2023",
    firstConnected: "2 Apr 2024",
    lastConnected: "4 Apr 2024",
    lat: 23.026803,
    lng: 72.619063,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {
      "2024-06-22": [
        {
          time: 0,
          label: "2:35pm",
          lat: 23.023181050090926,
          lng: 72.53032393165415,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "2:40pm",
          lat: 23.023240943255146,
          lng: 72.53032484831826,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "2:45pm",
          lat: 23.021775765400747,
          lng: 72.53027870406402,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "2:50pm",
          lat: 23.021335147207566,
          lng: 72.53029024012764,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "2:55pm",
          lat: 23.021202430601946,
          lng: 72.53024409587337,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "3pm",
          lat: 23.021213047164906,
          lng: 72.5302267899875,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "3:05pm",
          lat: 23.019317839937898,
          lng: 72.53019218179801,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "3:10pm",
          lat: 23.01910018138041,
          lng: 72.53018064573439,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "3:15pm",
          lat: 23.019135931201035,
          lng: 72.53019144263769,
          speed: getRandomSpeed(),
        },
        {
          time: 10,
          label: "3:20pm",
          lat: 23.017352180014925,
          lng: 72.53024335492367,
          speed: getRandomSpeed(),
        },
      ],
      "2024-05-16": [
        {
          time: 0,
          label: "2:25pm",
          lat: 23.023972027521197,
          lng: 72.53032969968592,
          speed: getRandomSpeed(),
        },
        {
          time: 1,
          label: "2:30pm",
          lat: 23.023361541993268,
          lng: 72.53033546771769,
          speed: getRandomSpeed(),
        },
        {
          time: 2,
          label: "2:35pm",
          lat: 23.023181050090926,
          lng: 72.53032393165415,
          speed: getRandomSpeed(),
        },
        {
          time: 3,
          label: "2:40pm",
          lat: 23.023240943255146,
          lng: 72.53032484831826,
          speed: getRandomSpeed(),
        },
        {
          time: 4,
          label: "2:45pm",
          lat: 23.021775765400747,
          lng: 72.53027870406402,
          speed: getRandomSpeed(),
        },
        {
          time: 5,
          label: "2:50pm",
          lat: 23.021335147207566,
          lng: 72.53029024012764,
          speed: getRandomSpeed(),
        },
        {
          time: 6,
          label: "2:55pm",
          lat: 23.021202430601946,
          lng: 72.53024409587337,
          speed: getRandomSpeed(),
        },
        {
          time: 7,
          label: "3pm",
          lat: 23.021213047164906,
          lng: 72.5302267899875,
          speed: getRandomSpeed(),
        },
        {
          time: 8,
          label: "3:05pm",
          lat: 23.019317839937898,
          lng: 72.53019218179801,
          speed: getRandomSpeed(),
        },
        {
          time: 9,
          label: "3:10pm",
          lat: 23.01910018138041,
          lng: 72.53018064573439,
          speed: getRandomSpeed(),
        },
        {
          time: 10,
          label: "3:15pm",
          lat: 23.019135931201035,
          lng: 72.53019144263769,
          speed: getRandomSpeed(),
        },
      ],
    },
  },
  {
    id: 6,
    name: "Axons-06",
    imei: "#96279524",
    firmware: "0399331.3",
    status: "Shipping",
    hardware: "0033420",
    mfgDate: "10 Sep 2023",
    firstConnected: "1 May 2024",
    lastConnected: "18 May 2024",
    lat: 23.041675,
    lng: 72.643038,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 7,
    name: "Axons-07",
    imei: "#74929728",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033460",
    mfgDate: "22 Aug 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "6 Jun 2024",
    lat: 22.963306,
    lng: 72.494798,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 8,
    name: "Axons-08",
    imei: "#43658194",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033422",
    mfgDate: "13 Sep 2023",
    firstConnected: "17 Mar 2024",
    lastConnected: "26 Mar 2024",
    lat: 23.018496,
    lng: 72.577618,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 9,
    name: "Axons-09",
    imei: "#83904715",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033483",
    mfgDate: "7 Sep 2023",
    firstConnected: "25 May 2024",
    lastConnected: "25 May 2024",
    lat: 22.96067,
    lng: 72.515426,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 10,
    name: "Axons-10",
    imei: "#30745230",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033474",
    mfgDate: "26 Sep 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "20 May 2024",
    lat: 22.960269,
    lng: 72.62067,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 11,
    name: "Axons-11",
    imei: "#96754095",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033431",
    mfgDate: "4 Sep 2023",
    firstConnected: "2 May 2024",
    lastConnected: "18 May 2024",
    lat: 23.012522,
    lng: 72.633987,
    isSubscribed: true,
    subscribedDate: "10 Apr 2024",
    dates: {},
  },
  {
    id: 12,
    name: "Axons-150",
    imei: "#08732415",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033470",
    mfgDate: "14 Oct 2023",
    firstConnected: "4 Mar 2024",
    lastConnected: "15 Mar 2024",
    lat: 23.035504,
    lng: 72.594526,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 13,
    name: "Axons-12",
    imei: "#10483648",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033465",
    mfgDate: "1 Aug 2023",
    firstConnected: "18 Apr 2024",
    lastConnected: "6 Jun 2024",
    lat: 22.98108,
    lng: 72.503927,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 14,
    name: "Axons-13",
    imei: "#28365058",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033454",
    mfgDate: "26 Aug 2023",
    firstConnected: "6 Apr 2024",
    lastConnected: "13 May 2024",
    lat: 23.002275,
    lng: 72.555834,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 15,
    name: "Axons-14",
    imei: "#40759340",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033494",
    mfgDate: "20 Sep 2023",
    firstConnected: "8 May 2024",
    lastConnected: "19 May 2024",
    lat: 23.021944,
    lng: 72.641999,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 16,
    name: "Axons-15",
    imei: "#12046047",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033436",
    mfgDate: "1 Oct 2023",
    firstConnected: "7 Mar 2024",
    lastConnected: "10 Mar 2024",
    lat: 23.036806,
    lng: 72.645592,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 17,
    name: "Axons-16",
    imei: "#02967503",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033477",
    mfgDate: "2 Sep 2023",
    firstConnected: "24 Apr 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.065702,
    lng: 72.601794,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 18,
    name: "Axons-17",
    imei: "#42691765",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033433",
    mfgDate: "21 Sep 2023",
    firstConnected: "1 Mar 2024",
    lastConnected: "10 Apr 2024",
    lat: 22.980294,
    lng: 72.576119,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 19,
    name: "Axons-18",
    imei: "#75862439",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033487",
    mfgDate: "19 Sep 2023",
    firstConnected: "10 Apr 2024",
    lastConnected: "4 May 2024",
    lat: 22.998328,
    lng: 72.566155,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 20,
    name: "Axons-19",
    imei: "#10483265",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033476",
    mfgDate: "17 Oct 2023",
    firstConnected: "4 Mar 2024",
    lastConnected: "17 Apr 2024",
    lat: 23.029271,
    lng: 72.542764,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 21,
    name: "Axons-20",
    imei: "#27951062",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033479",
    mfgDate: "16 Sep 2023",
    firstConnected: "9 Apr 2024",
    lastConnected: "13 Apr 2024",
    lat: 22.993806,
    lng: 72.511256,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
  {
    id: 22,
    name: "Axons-21",
    imei: "#61074815",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033482",
    mfgDate: "7 Sep 2023",
    firstConnected: "23 Apr 2024",
    lastConnected: "18 May 2024",
    lat: 23.064149,
    lng: 72.593186,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 23,
    name: "Axons-22",
    imei: "#97582397",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033463",
    mfgDate: "15 Oct 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "12 Apr 2024",
    lat: 23.058821,
    lng: 72.500422,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 24,
    name: "Axons-23",
    imei: "#79462030",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033459",
    mfgDate: "9 Oct 2023",
    firstConnected: "12 Apr 2024",
    lastConnected: "24 May 2024",
    lat: 22.987529,
    lng: 72.501633,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 25,
    name: "Axons-24",
    imei: "#57821384",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033492",
    mfgDate: "10 Sep 2023",
    firstConnected: "15 Mar 2024",
    lastConnected: "18 Apr 2024",
    lat: 22.971374,
    lng: 72.555118,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 26,
    name: "Axons-25",
    imei: "#10358264",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033498",
    mfgDate: "17 Aug 2023",
    firstConnected: "20 Apr 2024",
    lastConnected: "12 May 2024",
    lat: 23.040721,
    lng: 72.622917,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 27,
    name: "Axons-26",
    imei: "#43587916",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033455",
    mfgDate: "5 Oct 2023",
    firstConnected: "6 Apr 2024",
    lastConnected: "23 Apr 2024",
    lat: 23.046366,
    lng: 72.622325,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 28,
    name: "Axons-27",
    imei: "#98473012",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033485",
    mfgDate: "2 Sep 2023",
    firstConnected: "28 Mar 2024",
    lastConnected: "1 Jun 2024",
    lat: 23.023487,
    lng: 72.619717,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 29,
    name: "Axons-28",
    imei: "#63597240",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033462",
    mfgDate: "24 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "30 Mar 2024",
    lat: 22.980547,
    lng: 72.506942,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 30,
    name: "Axons-29",
    imei: "#10327491",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033475",
    mfgDate: "14 Sep 2023",
    firstConnected: "12 Apr 2024",
    lastConnected: "26 Apr 2024",
    lat: 23.030207,
    lng: 72.625161,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 31,
    name: "Axons-30",
    imei: "#23419857",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033437",
    mfgDate: "2 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "20 Mar 2024",
    lat: 23.024965,
    lng: 72.644514,
    isSubscribed: false,
    subscribedDate: "15 Mar 2024",
    dates: {},
  },
  {
    id: 32,
    name: "Axons-31",
    imei: "#59837412",
    firmware: "0399331.3",
    status: "Shipping",
    hardware: "0033458",
    mfgDate: "1 Aug 2023",
    firstConnected: "24 Mar 2024",
    lastConnected: "7 Apr 2024",
    lat: 22.987923,
    lng: 72.590161,
    isSubscribed: false,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 33,
    name: "Axons-32",
    imei: "#19308462",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033442",
    mfgDate: "11 Aug 2023",
    firstConnected: "12 Apr 2024",
    lastConnected: "27 May 2024",
    lat: 23.050632,
    lng: 72.565277,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 34,
    name: "Axons-33",
    imei: "#84017695",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033461",
    mfgDate: "18 Aug 2023",
    firstConnected: "9 Apr 2024",
    lastConnected: "16 Apr 2024",
    lat: 23.011515,
    lng: 72.556699,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 35,
    name: "Axons-34",
    imei: "#70928351",
    firmware: "0399331.3",
    status: "Shipping",
    hardware: "0033447",
    mfgDate: "5 Aug 2023",
    firstConnected: "8 Apr 2024",
    lastConnected: "13 May 2024",
    lat: 23.060391,
    lng: 72.637925,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 36,
    name: "Axons-35",
    imei: "#58293740",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033431",
    mfgDate: "2 Oct 2023",
    firstConnected: "2 May 2024",
    lastConnected: "15 May 2024",
    lat: 23.008193,
    lng: 72.580603,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 37,
    name: "Axons-36",
    imei: "#92810475",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033437",
    mfgDate: "26 Sep 2023",
    firstConnected: "18 Apr 2024",
    lastConnected: "9 May 2024",
    lat: 23.046179,
    lng: 72.570225,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 38,
    name: "Axons-37",
    imei: "#75892063",
    firmware: "0399331.1",
    status: "Shipping",
    hardware: "0033443",
    mfgDate: "17 Oct 2023",
    firstConnected: "22 Apr 2024",
    lastConnected: "2 May 2024",
    lat: 22.98714,
    lng: 72.522879,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 39,
    name: "Axons-38",
    imei: "#32810567",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033493",
    mfgDate: "15 Sep 2023",
    firstConnected: "8 Apr 2024",
    lastConnected: "9 May 2024",
    lat: 22.999348,
    lng: 72.548788,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 40,
    name: "Axons-39",
    imei: "#91826047",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033438",
    mfgDate: "10 Aug 2023",
    firstConnected: "16 May 2024",
    lastConnected: "21 May 2024",
    lat: 23.059692,
    lng: 72.54296,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 41,
    name: "Axons-40",
    imei: "#34960175",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033476",
    mfgDate: "8 Sep 2023",
    firstConnected: "4 Apr 2024",
    lastConnected: "14 May 2024",
    lat: 23.025578,
    lng: 72.497274,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 42,
    name: "Axons-41",
    imei: "#79056182",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033460",
    mfgDate: "6 Sep 2023",
    firstConnected: "7 Mar 2024",
    lastConnected: "22 May 2024",
    lat: 23.04049,
    lng: 72.514169,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 43,
    name: "Axons-42",
    imei: "#62039485",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033490",
    mfgDate: "26 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "19 Apr 2024",
    lat: 23.016399,
    lng: 72.533827,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 44,
    name: "Axons-43",
    imei: "#43286517",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033434",
    mfgDate: "1 Oct 2023",
    firstConnected: "23 Apr 2024",
    lastConnected: "27 Apr 2024",
    lat: 23.065288,
    lng: 72.541145,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 45,
    name: "Axons-44",
    imei: "#92045871",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033489",
    mfgDate: "8 Oct 2023",
    firstConnected: "13 Mar 2024",
    lastConnected: "8 Apr 2024",
    lat: 22.980101,
    lng: 72.582697,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 46,
    name: "Axons-45",
    imei: "#01926358",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033467",
    mfgDate: "22 Aug 2023",
    firstConnected: "24 Apr 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.057142,
    lng: 72.497436,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 47,
    name: "Axons-46",
    imei: "#84729501",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033440",
    mfgDate: "16 Oct 2023",
    firstConnected: "3 May 2024",
    lastConnected: "17 May 2024",
    lat: 23.017324,
    lng: 72.591114,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 48,
    name: "Axons-47",
    imei: "#36417520",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033443",
    mfgDate: "27 Aug 2023",
    firstConnected: "8 Apr 2024",
    lastConnected: "23 Apr 2024",
    lat: 23.032594,
    lng: 72.545507,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 49,
    name: "Axons-48",
    imei: "#83641275",
    firmware: "0399331.3",
    status: "Shipping",
    hardware: "0033432",
    mfgDate: "9 Aug 2023",
    firstConnected: "24 Mar 2024",
    lastConnected: "2 Apr 2024",
    lat: 23.060282,
    lng: 72.552784,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 50,
    name: "Axons-49",
    imei: "#57491836",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033461",
    mfgDate: "8 Sep 2023",
    firstConnected: "14 Apr 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.049422,
    lng: 72.520708,
    isSubscribed: true,
    subscribedDate: "12 May 2024",
    dates: {},
  },
  {
    id: 51,
    name: "Axons-50",
    imei: "#12964735",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033450",
    mfgDate: "27 Aug 2023",
    firstConnected: "20 Apr 2024",
    lastConnected: "24 Apr 2024",
    lat: 23.053546,
    lng: 72.627856,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 52,
    name: "Axons-51",
    imei: "#74960182",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033442",
    mfgDate: "19 Aug 2023",
    firstConnected: "8 Apr 2024",
    lastConnected: "3 May 2024",
    lat: 23.060149,
    lng: 72.518925,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 53,
    name: "Axons-52",
    imei: "#89427350",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033498",
    mfgDate: "27 Sep 2023",
    firstConnected: "2 May 2024",
    lastConnected: "26 May 2024",
    lat: 23.050945,
    lng: 72.632174,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 54,
    name: "Axons-53",
    imei: "#09342516",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033467",
    mfgDate: "6 Sep 2023",
    firstConnected: "9 Mar 2024",
    lastConnected: "17 Apr 2024",
    lat: 23.041037,
    lng: 72.590309,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 55,
    name: "Axons-54",
    imei: "#52384761",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033464",
    mfgDate: "23 Sep 2023",
    firstConnected: "7 May 2024",
    lastConnected: "10 May 2024",
    lat: 22.963318,
    lng: 72.567789,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 56,
    name: "Axons-55",
    imei: "#47931625",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033479",
    mfgDate: "12 Sep 2023",
    firstConnected: "9 Mar 2024",
    lastConnected: "2 Apr 2024",
    lat: 23.02482,
    lng: 72.579231,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 57,
    name: "Axons-56",
    imei: "#84926173",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033438",
    mfgDate: "12 Oct 2023",
    firstConnected: "3 Mar 2024",
    lastConnected: "26 Apr 2024",
    lat: 23.020836,
    lng: 72.498147,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 58,
    name: "Axons-57",
    imei: "#06194752",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033443",
    mfgDate: "16 Aug 2023",
    firstConnected: "1 Apr 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.003589,
    lng: 72.580067,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 59,
    name: "Axons-58",
    imei: "#49261738",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033493",
    mfgDate: "11 Sep 2023",
    firstConnected: "16 Mar 2024",
    lastConnected: "3 May 2024",
    lat: 23.067109,
    lng: 72.637503,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 60,
    name: "Axons-59",
    imei: "#03284651",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033497",
    mfgDate: "27 Sep 2023",
    firstConnected: "17 Mar 2024",
    lastConnected: "4 May 2024",
    lat: 22.963941,
    lng: 72.609234,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 61,
    name: "Axons-60",
    imei: "#13842759",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033465",
    mfgDate: "8 Aug 2023",
    firstConnected: "1 Apr 2024",
    lastConnected: "6 May 2024",
    lat: 23.063387,
    lng: 72.510072,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 62,
    name: "Axons-61",
    imei: "#83926154",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033464",
    mfgDate: "25 Aug 2023",
    firstConnected: "25 Mar 2024",
    lastConnected: "16 May 2024",
    lat: 23.068729,
    lng: 72.598503,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 63,
    name: "Axons-62",
    imei: "#84976231",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033451",
    mfgDate: "14 Sep 2023",
    firstConnected: "8 Mar 2024",
    lastConnected: "1 May 2024",
    lat: 22.982361,
    lng: 72.551604,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 64,
    name: "Axons-63",
    imei: "#94127530",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033447",
    mfgDate: "10 Sep 2023",
    firstConnected: "19 Mar 2024",
    lastConnected: "30 Mar 2024",
    lat: 23.043605,
    lng: 72.525683,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 65,
    name: "Axons-64",
    imei: "#05291834",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033441",
    mfgDate: "3 Oct 2023",
    firstConnected: "18 Mar 2024",
    lastConnected: "23 Mar 2024",
    lat: 22.997627,
    lng: 72.600173,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 66,
    name: "Axons-65",
    imei: "#94827130",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033472",
    mfgDate: "4 Sep 2023",
    firstConnected: "27 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 22.989459,
    lng: 72.633764,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 67,
    name: "Axons-66",
    imei: "#83251794",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033434",
    mfgDate: "3 Aug 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "3 Jun 2024",
    lat: 22.980426,
    lng: 72.578015,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 68,
    name: "Axons-67",
    imei: "#73216489",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033474",
    mfgDate: "17 Sep 2023",
    firstConnected: "13 Mar 2024",
    lastConnected: "27 May 2024",
    lat: 23.043298,
    lng: 72.589123,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 69,
    name: "Axons-68",
    imei: "#34871652",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033449",
    mfgDate: "19 Aug 2023",
    firstConnected: "24 Apr 2024",
    lastConnected: "10 May 2024",
    lat: 23.024057,
    lng: 72.615148,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 70,
    name: "Axons-69",
    imei: "#04127539",
    firmware: "0399331.1",
    status: "Shipping",
    hardware: "0033477",
    mfgDate: "8 Aug 2023",
    firstConnected: "16 Mar 2024",
    lastConnected: "26 Apr 2024",
    lat: 23.007634,
    lng: 72.528151,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 71,
    name: "Axons-70",
    imei: "#43751298",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033462",
    mfgDate: "12 Oct 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "9 May 2024",
    lat: 23.019485,
    lng: 72.529783,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 72,
    name: "Axons-71",
    imei: "#57249138",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033431",
    mfgDate: "1 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 23.047306,
    lng: 72.536227,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 73,
    name: "Axons-72",
    imei: "#30947516",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033440",
    mfgDate: "21 Aug 2023",
    firstConnected: "18 Apr 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.05528,
    lng: 72.623889,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 74,
    name: "Axons-73",
    imei: "#57831492",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033485",
    mfgDate: "3 Sep 2023",
    firstConnected: "14 Mar 2024",
    lastConnected: "26 Apr 2024",
    lat: 23.043476,
    lng: 72.505326,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 75,
    name: "Axons-74",
    imei: "#10375962",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033493",
    mfgDate: "19 Oct 2023",
    firstConnected: "6 Apr 2024",
    lastConnected: "7 May 2024",
    lat: 22.991834,
    lng: 72.617932,
    isSubscribed: false,
    subscribedDate: "20 June 2024",
    dates: {},
  },
  {
    id: 76,
    name: "Axons-75",
    imei: "#45972183",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033471",
    mfgDate: "8 Sep 2023",
    firstConnected: "12 Mar 2024",
    lastConnected: "9 May 2024",
    lat: 23.033895,
    lng: 72.609346,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 77,
    name: "Axons-76",
    imei: "#71643925",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033449",
    mfgDate: "2 Oct 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "23 Apr 2024",
    lat: 23.019975,
    lng: 72.618329,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 78,
    name: "Axons-77",
    imei: "#94182753",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033439",
    mfgDate: "25 Oct 2023",
    firstConnected: "24 Mar 2024",
    lastConnected: "5 May 2024",
    lat: 23.010692,
    lng: 72.591692,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 79,
    name: "Axons-78",
    imei: "#10364725",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033473",
    mfgDate: "10 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.06847,
    lng: 72.566117,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 80,
    name: "Axons-79",
    imei: "#17592036",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033457",
    mfgDate: "11 Oct 2023",
    firstConnected: "12 Apr 2024",
    lastConnected: "25 May 2024",
    lat: 23.065269,
    lng: 72.594808,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 81,
    name: "Axons-80",
    imei: "#74812039",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033458",
    mfgDate: "16 Oct 2023",
    firstConnected: "4 May 2024",
    lastConnected: "18 May 2024",
    lat: 23.034269,
    lng: 72.609248,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 82,
    name: "Axons-81",
    imei: "#51823647",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033441",
    mfgDate: "8 Sep 2023",
    firstConnected: "4 May 2024",
    lastConnected: "8 May 2024",
    lat: 23.043047,
    lng: 72.533723,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 83,
    name: "Axons-82",
    imei: "#23647198",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033457",
    mfgDate: "4 Aug 2023",
    firstConnected: "10 Apr 2024",
    lastConnected: "2 May 2024",
    lat: 23.020693,
    lng: 72.596203,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 84,
    name: "Axons-83",
    imei: "#13478295",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033471",
    mfgDate: "9 Oct 2023",
    firstConnected: "10 Apr 2024",
    lastConnected: "25 May 2024",
    lat: 23.017594,
    lng: 72.515209,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 85,
    name: "Axons-84",
    imei: "#47295138",
    firmware: "0399331.1",
    status: "Shipping",
    hardware: "0033479",
    mfgDate: "27 Sep 2023",
    firstConnected: "16 Apr 2024",
    lastConnected: "27 Apr 2024",
    lat: 23.045469,
    lng: 72.644314,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 86,
    name: "Axons-85",
    imei: "#54872310",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033433",
    mfgDate: "24 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "3 May 2024",
    lat: 23.007685,
    lng: 72.513428,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 87,
    name: "Axons-86",
    imei: "#34958721",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033474",
    mfgDate: "4 Aug 2023",
    firstConnected: "1 May 2024",
    lastConnected: "6 May 2024",
    lat: 22.974359,
    lng: 72.622417,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 88,
    name: "Axons-87",
    imei: "#08412735",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033435",
    mfgDate: "17 Aug 2023",
    firstConnected: "28 Apr 2024",
    lastConnected: "17 May 2024",
    lat: 23.050319,
    lng: 72.565003,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 89,
    name: "Axons-88",
    imei: "#87164293",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033430",
    mfgDate: "12 Aug 2023",
    firstConnected: "20 Mar 2024",
    lastConnected: "22 May 2024",
    lat: 23.021368,
    lng: 72.630125,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 90,
    name: "Axons-89",
    imei: "#09275134",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033498",
    mfgDate: "18 Aug 2023",
    firstConnected: "10 Apr 2024",
    lastConnected: "9 May 2024",
    lat: 23.049201,
    lng: 72.508526,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 91,
    name: "Axons-90",
    imei: "#91827543",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033448",
    mfgDate: "11 Aug 2023",
    firstConnected: "14 Apr 2024",
    lastConnected: "22 Apr 2024",
    lat: 23.048039,
    lng: 72.622933,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 92,
    name: "Axons-91",
    imei: "#36587241",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033452",
    mfgDate: "25 Aug 2023",
    firstConnected: "15 Apr 2024",
    lastConnected: "1 May 2024",
    lat: 23.030169,
    lng: 72.502504,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 93,
    name: "Axons-92",
    imei: "#65238471",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033496",
    mfgDate: "19 Oct 2023",
    firstConnected: "13 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 23.008439,
    lng: 72.58363,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 94,
    name: "Axons-93",
    imei: "#78132496",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033488",
    mfgDate: "24 Oct 2023",
    firstConnected: "11 Apr 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.016175,
    lng: 72.567909,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 95,
    name: "Axons-94",
    imei: "#09348271",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033453",
    mfgDate: "4 Oct 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 23.054274,
    lng: 72.543287,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 96,
    name: "Axons-95",
    imei: "#37481925",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033497",
    mfgDate: "15 Aug 2023",
    firstConnected: "17 Mar 2024",
    lastConnected: "25 May 2024",
    lat: 23.040768,
    lng: 72.600842,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 97,
    name: "Axons-96",
    imei: "#62837415",
    firmware: "0399331.2",
    status: "Shipping",
    hardware: "0033455",
    mfgDate: "16 Oct 2023",
    firstConnected: "28 Mar 2024",
    lastConnected: "3 May 2024",
    lat: 23.066792,
    lng: 72.624583,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 98,
    name: "Axons-97",
    imei: "#83741529",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033459",
    mfgDate: "23 Sep 2023",
    firstConnected: "9 Apr 2024",
    lastConnected: "12 May 2024",
    lat: 23.045126,
    lng: 72.551997,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 99,
    name: "Axons-98",
    imei: "#02987135",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033444",
    mfgDate: "5 Sep 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.00946,
    lng: 72.623742,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 100,
    name: "Axons-99",
    imei: "#71938425",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033469",
    mfgDate: "15 Oct 2023",
    firstConnected: "14 Mar 2024",
    lastConnected: "21 May 2024",
    lat: 23.029754,
    lng: 72.505729,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 101,
    name: "Axons-100",
    imei: "#18462759",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033447",
    mfgDate: "26 Aug 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 22.993017,
    lng: 72.589514,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 102,
    name: "Axons-101",
    imei: "#42917538",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033487",
    mfgDate: "1 Oct 2023",
    firstConnected: "24 Mar 2024",
    lastConnected: "3 May 2024",
    lat: 22.982943,
    lng: 72.524325,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 103,
    name: "Axons-102",
    imei: "#81423975",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033489",
    mfgDate: "7 Aug 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "21 May 2024",
    lat: 23.01342,
    lng: 72.593574,
    isSubscribed: true,
    subscribedDate: "22 July 2024",
    dates: {},
  },
  {
    id: 104,
    name: "Axons-103",
    imei: "#53782149",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033461",
    mfgDate: "12 Aug 2023",
    firstConnected: "16 Apr 2024",
    lastConnected: "1 May 2024",
    lat: 22.996032,
    lng: 72.608631,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 105,
    name: "Axons-104",
    imei: "#72519043",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033464",
    mfgDate: "2 Sep 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 22.998752,
    lng: 72.531626,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 106,
    name: "Axons-105",
    imei: "#84971562",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033450",
    mfgDate: "4 Aug 2023",
    firstConnected: "6 May 2024",
    lastConnected: "12 May 2024",
    lat: 22.989319,
    lng: 72.563507,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 107,
    name: "Axons-106",
    imei: "#92847361",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033493",
    mfgDate: "26 Aug 2023",
    firstConnected: "5 Apr 2024",
    lastConnected: "3 May 2024",
    lat: 23.008273,
    lng: 72.566935,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 108,
    name: "Axons-107",
    imei: "#19423758",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033483",
    mfgDate: "20 Aug 2023",
    firstConnected: "2 Apr 2024",
    lastConnected: "25 May 2024",
    lat: 23.045629,
    lng: 72.551887,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 109,
    name: "Axons-108",
    imei: "#20487513",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033457",
    mfgDate: "9 Oct 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "26 Apr 2024",
    lat: 23.025385,
    lng: 72.518873,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 110,
    name: "Axons-109",
    imei: "#72594183",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033489",
    mfgDate: "12 Sep 2023",
    firstConnected: "16 Mar 2024",
    lastConnected: "5 May 2024",
    lat: 22.973419,
    lng: 72.552833,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 111,
    name: "Axons-110",
    imei: "#58271934",
    firmware: "0399331.1",
    status: "Shipping",
    hardware: "0033477",
    mfgDate: "1 Aug 2023",
    firstConnected: "7 May 2024",
    lastConnected: "10 May 2024",
    lat: 23.002341,
    lng: 72.605614,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 112,
    name: "Axons-111",
    imei: "#04792851",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033440",
    mfgDate: "19 Oct 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 23.028763,
    lng: 72.544193,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 113,
    name: "Axons-112",
    imei: "#01982745",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033497",
    mfgDate: "18 Sep 2023",
    firstConnected: "17 Mar 2024",
    lastConnected: "24 May 2024",
    lat: 23.037219,
    lng: 72.59371,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 114,
    name: "Axons-113",
    imei: "#49581723",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033451",
    mfgDate: "22 Aug 2023",
    firstConnected: "8 May 2024",
    lastConnected: "12 May 2024",
    lat: 23.013876,
    lng: 72.614295,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 115,
    name: "Axons-114",
    imei: "#37594026",
    firmware: "0399331.1",
    status: "Shipping",
    hardware: "0033438",
    mfgDate: "16 Sep 2023",
    firstConnected: "10 Apr 2024",
    lastConnected: "25 May 2024",
    lat: 23.040572,
    lng: 72.508329,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 116,
    name: "Axons-115",
    imei: "#23874915",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033453",
    mfgDate: "11 Sep 2023",
    firstConnected: "25 Mar 2024",
    lastConnected: "17 May 2024",
    lat: 22.976527,
    lng: 72.537408,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 117,
    name: "Axons-116",
    imei: "#82034917",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033442",
    mfgDate: "25 Oct 2023",
    firstConnected: "4 Apr 2024",
    lastConnected: "3 Jun 2024",
    lat: 22.980346,
    lng: 72.614926,
    isSubscribed: true,
    subscribedDate: "20 Aug 2024",
    dates: {},
  },
  {
    id: 118,
    name: "Axons-117",
    imei: "#91372854",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033444",
    mfgDate: "5 Sep 2023",
    firstConnected: "5 May 2024",
    lastConnected: "12 May 2024",
    lat: 23.063201,
    lng: 72.567233,
    isSubscribed: true,
    subscribedDate: "5 Aug 2024",
    dates: {},
  },
  {
    id: 119,
    name: "Axons-118",
    imei: "#45839271",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033446",
    mfgDate: "19 Sep 2023",
    firstConnected: "18 Apr 2024",
    lastConnected: "8 May 2024",
    lat: 23.010245,
    lng: 72.604179,
    isSubscribed: true,
    subscribedDate: "5 Aug 2024",
    dates: {},
  },
  {
    id: 120,
    name: "Axons-119",
    imei: "#63782051",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033450",
    mfgDate: "15 Aug 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "5 May 2024",
    lat: 23.048063,
    lng: 72.516083,
    isSubscribed: true,
    subscribedDate: "5 Aug 2024",
    dates: {},
  },
  {
    id: 121,
    name: "Axons-120",
    imei: "#12435987",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033443",
    mfgDate: "4 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "25 May 2024",
    lat: 22.978135,
    lng: 72.529601,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 122,
    name: "Axons-121",
    imei: "#20194785",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033441",
    mfgDate: "6 Sep 2023",
    firstConnected: "8 May 2024",
    lastConnected: "14 May 2024",
    lat: 23.046518,
    lng: 72.503972,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 123,
    name: "Axons-122",
    imei: "#58291734",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033445",
    mfgDate: "7 Aug 2023",
    firstConnected: "20 Apr 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.027149,
    lng: 72.578243,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 124,
    name: "Axons-123",
    imei: "#71084923",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033446",
    mfgDate: "16 Aug 2023",
    firstConnected: "25 Apr 2024",
    lastConnected: "25 May 2024",
    lat: 23.056891,
    lng: 72.552051,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 125,
    name: "Axons-124",
    imei: "#91347528",
    firmware: "0399331.3",
    status: "Disconnected",
    hardware: "0033451",
    mfgDate: "24 Sep 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "8 May 2024",
    lat: 23.018726,
    lng: 72.606176,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 126,
    name: "Axons-125",
    imei: "#74916382",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033448",
    mfgDate: "11 Oct 2023",
    firstConnected: "3 Apr 2024",
    lastConnected: "10 Apr 2024",
    lat: 23.028015,
    lng: 72.566258,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 127,
    name: "Axons-126",
    imei: "#27385104",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033447",
    mfgDate: "20 Oct 2023",
    firstConnected: "9 Apr 2024",
    lastConnected: "8 May 2024",
    lat: 23.040813,
    lng: 72.625019,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 128,
    name: "Axons-127",
    imei: "#52398146",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033445",
    mfgDate: "8 Sep 2023",
    firstConnected: "3 Apr 2024",
    lastConnected: "12 May 2024",
    lat: 23.008793,
    lng: 72.596725,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 129,
    name: "Axons-128",
    imei: "#93284715",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033449",
    mfgDate: "27 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "8 Apr 2024",
    lat: 23.037629,
    lng: 72.602108,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 130,
    name: "Axons-129",
    imei: "#58479132",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033443",
    mfgDate: "7 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "1 May 2024",
    lat: 23.020013,
    lng: 72.536925,
    isSubscribed: false,
    subscribedDate: "7 Aug 2024",
    dates: {},
  },
  {
    id: 131,
    name: "Axons-130",
    imei: "#84271963",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033445",
    mfgDate: "25 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.009478,
    lng: 72.600326,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 132,
    name: "Axons-131",
    imei: "#74591826",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033442",
    mfgDate: "21 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "16 May 2024",
    lat: 23.050632,
    lng: 72.631215,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 133,
    name: "Axons-132",
    imei: "#18392745",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033446",
    mfgDate: "17 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "30 May 2024",
    lat: 22.960732,
    lng: 72.522204,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 134,
    name: "Axons-133",
    imei: "#56723914",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033445",
    mfgDate: "20 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 22.980942,
    lng: 72.505174,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 135,
    name: "Axons-134",
    imei: "#39857124",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033444",
    mfgDate: "5 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "25 May 2024",
    lat: 23.018247,
    lng: 72.59438,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 136,
    name: "Axons-135",
    imei: "#72419385",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033442",
    mfgDate: "16 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "8 May 2024",
    lat: 23.030783,
    lng: 72.512804,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 137,
    name: "Axons-136",
    imei: "#84937512",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033447",
    mfgDate: "3 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "20 May 2024",
    lat: 23.062147,
    lng: 72.530872,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 138,
    name: "Axons-137",
    imei: "#37495812",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033451",
    mfgDate: "21 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.013847,
    lng: 72.592489,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 139,
    name: "Axons-138",
    imei: "#29843517",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033446",
    mfgDate: "17 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "8 May 2024",
    lat: 23.041062,
    lng: 72.540983,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 140,
    name: "Axons-139",
    imei: "#94781235",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033450",
    mfgDate: "7 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "6 Jun 2024",
    lat: 23.050813,
    lng: 72.503192,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 141,
    name: "Axons-140",
    imei: "#05172694",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033449",
    mfgDate: "5 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 22.987659,
    lng: 72.618512,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 142,
    name: "Axons-141",
    imei: "#58132749",
    firmware: "0399331.1",
    status: "Disconnected",
    hardware: "0033441",
    mfgDate: "16 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "20 May 2024",
    lat: 23.030274,
    lng: 72.560416,
    isSubscribed: true,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 143,
    name: "Axons-142",
    imei: "#31495872",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033445",
    mfgDate: "19 Oct 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "26 May 2024",
    lat: 22.971732,
    lng: 72.538749,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 144,
    name: "Axons-143",
    imei: "#47812396",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033446",
    mfgDate: "3 Aug 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 23.054729,
    lng: 72.54832,
    isSubscribed: false,
    subscribedDate: "6 Aug 2024",
    dates: {},
  },
  {
    id: 145,
    name: "Axons-144",
    imei: "#39248175",
    firmware: "0399331.2",
    status: "Disconnected",
    hardware: "0033442",
    mfgDate: "17 Oct 2023",
    firstConnected: "7 Apr 2024",
    lastConnected: "8 May 2024",
    lat: 23.022018,
    lng: 72.554237,
    isSubscribed: false,
    subscribedDate: "10 May 2024",
    dates: {},
  },
  {
    id: 146,
    name: "Axons-145",
    imei: "#05234781",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033441",
    mfgDate: "12 Aug 2023",
    firstConnected: "5 Mar 2024",
    lastConnected: "6 May 2024",
    lat: 23.018475,
    lng: 72.63264,
    isSubscribed: false,
    subscribedDate: "10 May 2024",
    dates: {},
  },
  {
    id: 147,
    name: "Axons-146",
    imei: "#38274519",
    firmware: "0399331.2",
    status: "Connected",
    hardware: "0033450",
    mfgDate: "18 Oct 2023",
    firstConnected: "2 Apr 2024",
    lastConnected: "2 Jun 2024",
    lat: 23.056329,
    lng: 72.504982,
    isSubscribed: false,
    subscribedDate: "10 June 2024",
    dates: {},
  },
  {
    id: 148,
    name: "Axons-147",
    imei: "#18593741",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033446",
    mfgDate: "20 Sep 2023",
    firstConnected: "2 Mar 2024",
    lastConnected: "10 Apr 2024",
    lat: 23.024159,
    lng: 72.544509,
    isSubscribed: false,
    subscribedDate: "10 June 2024",
    dates: {},
  },
  {
    id: 149,
    name: "Axons-148",
    imei: "#57419385",
    firmware: "0399331.1",
    status: "Connected",
    hardware: "0033443",
    mfgDate: "11 Aug 2023",
    firstConnected: "6 Apr 2024",
    lastConnected: "17 Apr 2024",
    lat: 23.049175,
    lng: 72.528734,
    isSubscribed: false,
    subscribedDate: "10 Jun 2024",
    dates: {},
  },
  {
    id: 150,
    name: "Axons-149",
    imei: "#92457831",
    firmware: "0399331.3",
    status: "Connected",
    hardware: "0033449",
    mfgDate: "3 Oct 2023",
    firstConnected: "8 May 2024",
    lastConnected: "3 Jun 2024",
    lat: 23.037128,
    lng: 72.61687,
    isSubscribed: false,
    subscribedDate: "10 Mar 2024",
    dates: {},
  },
];

const tableCellList = [
  "SL No",
  "Name",
  "IMEI",
  "Firmware",
  "Status",
  "Hardware",
  "Manufacturing Date",
  "First Connected Date",
  "Last Connected Date",
];

const firmwareOptions = ["0399331.1", "0399331.2", "0399331.3"];

const tableCellItem = ["User ID", "Name", "Email", "Devices", "Actions"];

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [devices, setDevices] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedKPIs, setSelectedKPIs] = useState([1, 2, 3, 4]);

  useEffect(() => {
    setDevices(initialDevices);
    setUsers([
      {
        id: 1,
        name: "carter",
        devices: [
          "#40583888",
          "#59754838",
          "#67320037",
          "#74073510",
          "#83137530",
        ],
        email: "carter@mail.com",
      },
      {
        id: 2,
        name: "liam",
        devices: ["#83904715"],
        email: "liam@mail.com",
      },
    ]);
  }, []);

  return (
    <DataContext.Provider
      value={{
        devices,
        setDevices,
        users,
        setUsers,
        tableCellList,
        firmwareOptions,
        tableCellItem,
        setSelectedKPIs,
        selectedKPIs,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
