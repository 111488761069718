import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  selectedId: null,
};

const assetTypeSlice = createSlice({
  name: "assetType",
  initialState,
  reducers: {
    updateAssetType: (state, action) => {
      state.data = action.payload;
    },
    updateSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateAssetType, updateSelectedId } = assetTypeSlice.actions;
export default assetTypeSlice.reducer;
