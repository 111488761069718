// src/slices/devicesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  historyData: null,
  historyDetailsData: null,
};

const updateHistorySlice = createSlice({
  name: "updateHistory",
  initialState,
  reducers: {
    getHistoryData: (state, action) => {
      state.historyData = action.payload;
    },
    getHistoryDetails: (state, action) => {
      state.historyDetailsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { getHistoryData, getHistoryDetails } = updateHistorySlice.actions;
export default updateHistorySlice.reducer;
