// src/slices/analyticsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: "heyy",
  kpiData: [],
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    updateAnalytics: (state, action) => {
      state.data = action.payload;
    },
    updateKpi: (state, action) => {
      state.kpiData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateAnalytics, updateKpi } = analyticsSlice.actions;
export default analyticsSlice.reducer;
