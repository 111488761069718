export const GetTabPermissons = (tabName, permissionData) => {
  // First, ensure permissionData is an object and not null or undefined
  if (!permissionData || typeof permissionData !== 'object' || Object.keys(permissionData).length === 0) {
    return {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }

  // Then check if the tabName key exists in permissionData
  if (permissionData.hasOwnProperty(tabName)) {
    return permissionData[tabName];
  }

  return null;
};