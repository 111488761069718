import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import LoadingUser from "./components/LoadingUser";
import PaymentSuccess from "./components/PaymentSuccess";
import PageNotFound from "./components/PageNotFound";
import PaymentMethod from "./pages/Payments";
import { useSelector } from "react-redux";
// Lazy load the components
const Dashboard = lazy(() => import("./pages/Dashboard/index"));
const Login = lazy(() => import("./pages/Login/LoginPage"));

const AppRoutes = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);

  return (
    <Suspense fallback={<LoadingUser />}>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthenticated ? <Login /> : <Navigate to="/dashboard" replace />
          }
        />
        <Route
          path="/dashboard/*"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="payment/:id" element={<PaymentMethod />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <CampaignWidget pageData="Have a good day"/> */}
    </Suspense>
  );
};

export default AppRoutes;
