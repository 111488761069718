// src/slices/devicesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  issueTypes: null,
 
};

const workOrderSlice = createSlice({
  name: "updateHistory",
  initialState,
  reducers: {
    setIssueTypes: (state, action) => {
      state.issueTypes = action.payload;
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { setIssueTypes } = workOrderSlice.actions;
export default workOrderSlice.reducer;
