import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarOptions: null,
  organisationId: null,
};

const sidebarSlices = createSlice({
  name: "sidebaeOptions",
  initialState,
  reducers: {
    updateSidebarOptions: (state, action) => {
      state.sidebarOptions = action.payload;
    },
    updateOrganisationId: (state, action) => {
      state.organisationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const { updateSidebarOptions, updateOrganisationId } =
  sidebarSlices.actions;
export default sidebarSlices.reducer;
