// src/api.js
import axios from "axios";
import { store } from "./store";

const api = axios.create({
  baseURL: "https://api.axonslab.io/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.authToken;
    const orgId = state?.sidebaroptions?.organisationId;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (orgId) {
      config.headers["org-id"] = orgId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const FileUploadapi = axios.create({
  baseURL: "https://api.axonslab.io/api",
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

FileUploadapi.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.authToken;
    const orgId = state?.sidebaroptions?.organisationId;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (orgId) {
      config.headers["org-id"] = orgId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
