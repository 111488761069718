import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import pagenotFound from "../assets/image/404.gif";

const buttonStyleDownload = {
  color: "white",
  marginTop: "0.875rem",
  borderRadius: "0.75rem",
  width: "45%",
  height: "2.813rem",
  textTransform: "none",
  background:
    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
};

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleReturnToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Card
      sx={{ justifyContent: "center", alignItems: "center", boxShadow: "none" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "95vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            maxWidth: "600px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <img
            src={pagenotFound}
            alt="Page Not Found"
            style={{ width: "300px", marginBottom: "1rem" }}
          />
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "1rem",
              color: "#283246",
              marginBottom: "1.5rem",
              fontFamily: "DM Sans, Arial, sans-serif",
            }}
          >
            Oops! The page you're looking for doesn't exist. Please check the
            URL or go back to the homepage.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              fontFamily: "DM Sans, Arial, sans-serif",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleReturnToDashboard}
              style={buttonStyleDownload}
              sx={{
                "&:hover": {
                  background:
                    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                  boxShadow: "none",
                  transform: "scale(1.05)",
                },
              }}
            >
              Go to Homepage
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PageNotFound;
