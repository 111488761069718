import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  authToken: null,
  otpTimeStamp: null,
  email: "",
  newOtp: "",
  currentUserRole: "",
  currentUserType: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginEmail: (state, action) => {
      state.email = action.payload;
    },
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setOtpTimeStamp: (state, action) => {
      state.otpTimeStamp = action.payload;
    },
    setOtpNew: (state, action) => {
      state.newOtp = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentUserRole = action.payload;
    },
    setCurrentUserType: (state, action) => {
      state.currentUserType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  login,
  logout,
  setAuthToken,
  loginEmail,
  setOtpTimeStamp,
  setOtpNew,
  setCurrentRole,
  setCurrentUserType,
} = authSlice.actions;
export default authSlice.reducer;
